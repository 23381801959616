<template>
  <section>
    <v-row v-if="isShowGroupBtn" justify="end" class="mt-4">
      <v-col v-if="isShowBtnAccident" cols="12" sm="auto" md="3">
        <v-btn
          block
          color="red"
          @click="showDialogAccident"
          class="white--text"
        >
          {{$_strings.order.REPORT_THE_PROBLEM}}
        </v-btn>
      </v-col>
      <v-col cols="12" sm="auto" md="auto">
        <v-btn
          v-if="textButton"
          color="primary body-2"
          block
          :loading="isLoadingShipmentDetails"
          @click="showDialogConfirmationDate"
        >
          {{textButton}}
        </v-btn>
      </v-col>
    </v-row>
    <DialogConfirmationDate
      ref="dialogConfirmationDate"
      @submit="submit"
    />
    <DialogAccident
      ref="dialogAccident"
      @fetchData="$emit('fetchData')"
    />
  </section>
</template>

<script>
import { generateFunctionUpdateShipment, findSequence } from '@/helper/order.js';
import DialogConfirmationDate from '../Dialog/ConfirmationDate.vue';
import DialogAccident from '../Dialog/Accident.vue';

export default {
  components: {
    DialogConfirmationDate,
    DialogAccident,
  },
  props: {
    itemSequence: {
      type: Object,
      default: () => {},
    },
    routeForUpdate: {
      type: Object,
      default: () => {},
    },
    isLoadingShipmentDetails: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isShowGroupBtn() {
      const {
        status,
        currentSequence,
        isCurrentSequenceArrived,
        isCurrentSequenceBASTSubmitted,
        isCurrentSequenceFinish,
      } = this.routeForUpdate;
      if (
        currentSequence === 0
        && !isCurrentSequenceArrived
        && !isCurrentSequenceBASTSubmitted
        && !isCurrentSequenceFinish
      ) {
        if (status === 'Terkonfirmasi') return true;
        return false;
      }
      return this.itemSequence.showBtnUpdateStatus || false;
    },
    isShowBtnAccident() {
      const {
        currentSequence,
      } = this.itemSequence;
      const {
        isAccident,
      } = this.routeForUpdate;
      return currentSequence > 0 && !isAccident;
    },
    textButton() {
      return this.itemSequence.text;
    },
  },
  methods: {
    findSequence,
    generateFunctionUpdateShipment,
    showDialogAccident() {
      this.$refs.dialogAccident.shipment = this.routeForUpdate;
      this.$refs.dialogAccident.dialog = true;
    },
    showDialogConfirmationDate() {
      this.$refs.dialogConfirmationDate.shipment = this.routeForUpdate;
      this.$refs.dialogConfirmationDate.dialog = true;
    },
    async submit(formConfirmationDate) {
      const { locationId, notes, status } = this.itemSequence;
      const { shipmentsCargoId } = this.routeForUpdate;
      const form = {
        ...formConfirmationDate,
        locationId,
        notes,
        status,
      };
      try {
        this.$refs.dialogConfirmationDate.isLoading = true;
        await this.$_services.order.updateStatusCargo(shipmentsCargoId, form);
        this.$dialog.notify.success('Berhasil update status kargo');
        this.$refs.dialogConfirmationDate.dialog = false;
        this.$emit('fetchData');
      } finally {
        this.$refs.dialogConfirmationDate.isLoading = false;
      }
    },
  },
};
</script>
